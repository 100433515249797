// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-christmas-js": () => import("./../../../src/pages/christmas.js" /* webpackChunkName: "component---src-pages-christmas-js" */),
  "component---src-pages-crab-js": () => import("./../../../src/pages/crab.js" /* webpackChunkName: "component---src-pages-crab-js" */),
  "component---src-pages-easter-js": () => import("./../../../src/pages/easter.js" /* webpackChunkName: "component---src-pages-easter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-thanksgiving-js": () => import("./../../../src/pages/thanksgiving.js" /* webpackChunkName: "component---src-pages-thanksgiving-js" */),
  "component---src-pages-valentines-js": () => import("./../../../src/pages/valentines.js" /* webpackChunkName: "component---src-pages-valentines-js" */)
}

